import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AppCommonModule} from '../../../../../../common-module/src/lib/app-common.module';
import {SharedModule} from '../../../shared/shared.module';
import { MapToolsModule } from '../map/map-tools.module';
import {VideoSharedModule} from "../video/video-shared.module";
import {ChartDialogComponent} from './chart-dialog/chart-dialog.component';
import {ReportOptionsComponent} from './report-options/report-options.component';
import {
  ReportSelectedOptionsComponent
} from './report-options/report-selected-options/report-selected-options.component';
import {ToolbarComponent} from './toolbar.component';
import {TrackOptionsComponent} from './track-options/track-options.component';
import { SelectGeofencesComponent } from './report-options/select-geofences/select-geofences.component';

@NgModule({
  declarations: [
    ToolbarComponent,
    ReportOptionsComponent,
    ReportSelectedOptionsComponent,
    TrackOptionsComponent,
    ChartDialogComponent,
    SelectGeofencesComponent
  ],
  imports: [
    AppCommonModule,
    CommonModule,
    SharedModule,
    VideoSharedModule,
    MapToolsModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    ToolbarComponent,
    ReportOptionsComponent,
    ReportSelectedOptionsComponent,
    TrackOptionsComponent,
    ChartDialogComponent
  ]
})

export class ToolbarModule {
}
