import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {ToolbarService} from '../../../shared/services/toolbar.service';
import {ReportBuildOptions} from './report-options/report-options.component';
import {ToolbarState} from '../../../shared/constants/enums/toolbar-state';
import {DateRangeI} from 'projects/common-module/src/lib/app-interfaces/date-range-i';
import {AuthUserService} from "../../../../../../common-module/src/lib/app-services/auth-user.service";
import {BaseUnsubscribeComponent} from "../../../shared/components/base-unsubscribe.component";
import {DayjsUtil} from "../../../../../../common-module/src/lib/dayjs.util";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToolbarComponent extends BaseUnsubscribeComponent implements OnInit, OnDestroy {

  public menuOptions: ToolbarState[] = [ToolbarState.REPORT, ToolbarState.TRACK, ToolbarState.CHART];

  public dateTimeRange$: Observable<DateRangeI>;
  public disableBuildButton = false;
  public menuLastOption = this.menuOptions[0];
  public selectedReportBuildOptions: ReportBuildOptions;
  public size$: Observable<ViewSize>;
  public toolbarState = ToolbarState;

  constructor(private viewBreakpointService: ViewBreakpointService,
              private cdr: ChangeDetectorRef,
              private toolbarService: ToolbarService) {
    super();
  }

  ngOnInit(): void {
    this.toolbarService.init();
    this.dateTimeRange$ = this.toolbarService.dateTimeRange$;
    this.size$ = this.viewBreakpointService.size$;

    this.toolbarService.toolbarOptions$
      .pipe(takeUntil(this.destroy))
      .subscribe(opts => this.menuOptions = opts);

    this.toolbarService.state$
      .pipe(takeUntil(this.destroy))
      .subscribe(v => {
        if (v.state !== ToolbarState.LOADING) {
          this.menuLastOption = v.state;
        }
        this.disableBuildButton = v.state === ToolbarState.LOADING;
        this.cdr.detectChanges();
      });
  }

  public changeDateTimeRange(range: DateRangeI): void {
    this.toolbarService.changeDateTimeRange(range);
  }

  public onBuild($event: MouseEvent): void {
    this.toolbarService.startBuild(this.menuLastOption, this.selectedReportBuildOptions, $event);
  }

  public onMenu(menuOption: ToolbarState): void {
    this.toolbarService.changeToolbarState(menuOption);
  }
}
