import Style from 'ol/style/Style';
import TextStyle from 'ol/style/Text';
import Text from 'ol/style/Text';
import FillStyle from 'ol/style/Fill';
import StrokeStyle from 'ol/style/Stroke';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import CircleStyle from 'ol/style/Circle';
import Circle from 'ol/style/Circle';
import {Fill} from 'ol/style';
import {Coordinate} from 'ol/coordinate';
import {TrackColor} from './track-color';
import {AppColor} from '../../../../../common-module/src/lib/app-enums/app-color';
import RegularShape from 'ol/style/RegularShape';
import {ColorUtil} from './color-util';
import {Font} from '../constants/font';
import {Size} from "ol/size";

export class OlStyle {

  public static createForIcon(imageUrl: string): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 23],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
        src: imageUrl,
      })
    });
  }

  public static createForIconWithAnchor(imageUrl: string, x: number, y: number, scale: number = 1): Style {
    return new Style({
      image: new Icon({
        anchor: [x, y],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
        scale: scale,
        src: imageUrl,
      })
    });
  }

  public static createForIconText(text: string, color: string): Style {
    return new Style({
      text: new TextStyle({
        text: text,
        offsetY: 18,
        fill: new FillStyle({color: AppColor.WHITE}),
        backgroundFill: new FillStyle({color: color ? color : AppColor.PRIMARY}),
        padding: [2, 2, 2, 2]
      })
    });
  }

  public static createForRotatedIcon(course: number, imageUrl: string): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 23],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
        src: imageUrl,
        rotateWithView: true,
        rotation: course * 0.01745329251
      })
    });
  }

  public static createForIconMarker(imageUrl: string): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: imageUrl
      })
    });
  }

  public static createForUnitsCluster(size: number): Style {
    return new Style({
      image: new Circle({
        radius: 13,
        fill: new Fill({color: AppColor.PRIMARY}),
        stroke: new Stroke({color: AppColor.WHITE, width: 2})
      }),
      text: new Text({
        text: size.toString(),
        fill: new Fill({color: AppColor.WHITE})
      })
    });
  }

  public static createForSolutionStageCluster(size: number): Style {
    return new Style({
      image: new Circle({
        radius: 13,
        fill: new Fill({color: AppColor.PRIMARY}),
        stroke: new Stroke({color: AppColor.WHITE, width: 2})
      }),
      text: new Text({
        text: size.toString(),
        fill: new Fill({color: AppColor.WHITE})
      })
    });
  }

  public static createForParkingCluster(size: number): Style {
    return new Style({
      image: new RegularShape({
        points: 3,
        radius: 15,
        rotation: 3.14159,
        fill: new Fill({color: AppColor.WHITE}),
        stroke: new Stroke({width: 3, color: AppColor.PRIMARY})
      }),
      text: new Text({
        text: size.toString(),
        textAlign: 'center',
        offsetY: 2,
        fill: new Fill({color: AppColor.BLACK}),
        stroke: new Stroke({width: 0.5})
      })
    });
  }

  public static createForVideoCluster(size: number): Style {
    const style = OlStyle.createForVideoPoint();
    style.setText(new Text({
      text: size.toString(),
      textAlign: 'center',
      offsetY: 2,
      fill: new Fill({color: AppColor.BLACK}),
      stroke: new Stroke({width: 0.5})
    }));
    return style;
  }

  public static createForSelectCluster(): Style {
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: AppColor.PRIMARY
        }),
        radius: 5
      })
    });
  }

  public static createForSelectedUnitAnimation(radius: number, opacity: number): Style {
    return new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: ColorUtil.hexToRgbModelColor(AppColor.ACCENT, opacity),
          width: 3 + opacity
        })
      })
    });
  }

  public static createForAnimationMarker(): Style {
    return new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: AppColor.BLACK}),
        stroke: new Stroke({
          color: AppColor.WHITE,
          width: 2,
        }),
      }),
    });
  }

  public static createForRoute(trackColor: AppColor, width: number = 2): Style {
    return new Style({
      stroke: new StrokeStyle({
        width: width,
        color: trackColor as string
      })
    });
  }

  public static createForSelectedRoute(): Style {
    return new Style({
      stroke: new StrokeStyle({
        width: 3,
        color: AppColor.ORANGE as string,
      }),
      zIndex: 2
    });
  }

  public static createForTrackArrow(rotation: number, trackColor: AppColor): Style {
    return new Style({
      image: new Icon({
        src: TrackColor.getColorTrackArrow(trackColor) as string,
        anchor: [0.75, 0.5],
        rotateWithView: true,
        rotation: -rotation
      })
    });
  }

  public static createForTrackPoint(): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: AppColor.ORANGE
        }),
        stroke: new Stroke({
          color: AppColor.GRAY,
          width: 1
        }),
        radius: 7
      })
    });
  }

  public static createForViolationPoint(color: string): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: color || AppColor.PRIMARY
        }),
        stroke: new Stroke({
          color: AppColor.GRAY,
          width: 1
        }),
        radius: 5
      })
    });
  }

  public static createForCheckPoint(): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: AppColor.LIGHT_GREY
        }),
        stroke: new Stroke({
          color: AppColor.GRAY,
          width: 1
        }),
        radius: 6
      })
    });
  }

  public static createForSelectedCheckPoint(): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: AppColor.ORANGE
        }),
        stroke: new Stroke({
          color: AppColor.BLACK,
          width: 2
        }),
        radius: 6
      }),
      zIndex: 3
    });
  }

  public static createCircle(radius: number, fillColor: AppColor, strokeColor: AppColor, strokeWidth: number = 3): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: fillColor
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth
        }),
        radius: radius
      })
    });
  }

  public static createCircleWithNumber(radius: number, fillColor: AppColor, strokeColor: AppColor, numb: number, numbColor: AppColor, strokeWidth: number = 3): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: fillColor
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth
        }),
        radius: radius
      }),
      text: new Text({
        text: '' + numb,
        font: 'bold 14px sans-serif',
        fill: new Fill({
          color: numbColor,
        }),
        offsetY: 2,
      }),
      zIndex: numb
    });
  }

  public static createForVideoPoint(): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: AppColor.WHITE
        }),
        stroke: new Stroke({
          color: AppColor.PRIMARY,
          width: 3
        }),
        radius: 12
      })
    });
  }

  public static createForSelectedVideoPoint(): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: AppColor.WHITE
        }),
        stroke: new Stroke({
          color: AppColor.TRACK_GREEN,
          width: 3
        }),
        radius: 12
      })
    });
  }

  public static createVideoIcon(imageUrl: string, isReady: boolean): Style {
    return new Style({
      image: new Icon({
        src: imageUrl,
        size: [18, 18],
        color: isReady ? AppColor.PRIMARY : AppColor.LIGHT_GREY
      }),
      zIndex: 2
    });
  }

  public static createIcon(iconUrl: string, iconColor: AppColor, sizeWH: Size): Style {
    return new Style({
      image: new Icon({
        src: iconUrl,
        size: sizeWH,
        color: iconColor
      }),
      zIndex: 2
    });
  }

  public static createForTrace(): Style {
    return new Style({
      stroke: new StrokeStyle({
        width: 2,
        color: AppColor.PRIMARY
      }),
    });
  }

  public static createForTraceSegment(start: Coordinate): Style {
    return new Style({
      geometry: new Point(start),
      image: new CircleStyle({
        radius: 2,
        fill: new Fill({color: AppColor.BLACK}),
        stroke: new Stroke({
          color: AppColor.WHITE,
          width: 2,
        }),
      }),
    });
  }

  public static createForMeasure(): Style {
    return new Style({
      fill: new Fill({
        color: ColorUtil.hexToRgbModelColor(AppColor.WHITE, 0.5)
      }),
      stroke: new Stroke({
        color: ColorUtil.hexToRgbModelColor(AppColor.BLACK, 0.5),
        lineDash: [10, 10],
        width: 2
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: ColorUtil.hexToRgbModelColor(AppColor.BLACK, 0.7)
        }),
        fill: new Fill({
          color: ColorUtil.hexToRgbModelColor(AppColor.WHITE, 0.2)
        })
      })
    });
  }

  public static createForMeasuringLayer(): Style {
    return new Style({
      fill: new Fill({
        color: ColorUtil.hexToRgbModelColor(AppColor.LIGHT_GREY, 0.5)
      }),
      stroke: new Stroke({
        color: AppColor.BLACK,
        width: 2
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: AppColor.PRIMARY
        })
      })
    });
  }

  public static createForMeasuringResultIcon(): Style {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: AppColor.PRIMARY
        }),
        stroke: new Stroke({color: AppColor.BLACK, width: 2}),
        radius: 3
      })
    });
  }

  public static createForGeofencePolygon(color: string, opacity: number): Style {
    const c = ColorUtil.hexToRgbModelColor(color, opacity);
    return new Style({
      fill: new Fill({
        color: c
      }),
      stroke: new Stroke({
        color: c,
        width: 2
      })
    });
  }

  public static createForDashedGeofencePolygon(color: string, opacity: number): Style {
    return new Style({
      fill: new Fill({
        color: ColorUtil.hexToRgbModelColor(color, opacity)
      }),
      stroke: new Stroke({
        color: AppColor.BLACK,
        width: 5,
        lineDash: [15, 10]
      })
    });
  }

  public static createGeofenceLabel(title: string): Style {
    return new Style({
      text: new Text({
        font: Font.CALIBRI_12,
        overflow: true,
        fill: new Fill({
          color: AppColor.BLACK
        }),
        backgroundFill: new Fill({
          color: AppColor.WHITE
        }),
        text: title,
      }),
    });
  }
}
