<form *ngIf="track$ | async" [formGroup]="trackOptionsForm">

  <button *ngIf="(size$ | async).more('m')"
          class="app-icon-button primary"
          mat-icon-button
          [disabled]="loadingVideoPoints$ | async"
          [matMenuTriggerFor]="menu"
          [matTooltip]="'term.settings' | translate">
    <mat-icon>settings</mat-icon>
    <mat-icon *ngIf="loadingVideoPoints$ | async" class="spinner-icon">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngTemplateOutlet="checkboxes"></ng-container>
  </mat-menu>

  <div *ngIf="(size$ | async).less('l')">
    <ng-container *ngTemplateOutlet="checkboxes"></ng-container>
  </div>

  <ng-template #checkboxes>
    <div class="flex flex-direction-column flex-gap8 margin-8">

      <h5>Отображение превышения скорости</h5>
      <mat-form-field [floatLabel]="'always'" (click)="$event.stopPropagation()">
        <mat-label> {{ 'term.max-speed' | translate }}</mat-label>
        <input matInput
               formControlName="maxSpeed"
               type="number"
               min="0"
               max="300"
               step="5"
               [matTooltip]="'message.tooltip.highlight-the-exceeded-target-speed' | translate"
               [matTooltipShowDelay]="1000">
        <span matTextSuffix>{{ 'uom.km-h' | translate }}</span>
      </mat-form-field>

      <mat-form-field [floatLabel]="'always'" (click)="$event.stopPropagation()">
        <mat-label>{{ 'message.info.min-period-of-exceeding' | translate }}</mat-label>
        <input matInput
               formControlName="minDurationOverSpeed"
               type="number"
               min="0"
               [matTooltip]="'message.tooltip.min-period-of-exceeding-max-speed' | translate"
               [matTooltipShowDelay]="500">
        <span matTextSuffix
              [matTooltip]="'time.seconds' | translate"
              [matTooltipShowDelay]="1000">{{ 'uom.sec' | translate }}</span>
      </mat-form-field>

      <h5>Отображение точек трека</h5>

      <mat-checkbox
        formControlName="isShowParkings"
        [matTooltip]="'message.tooltip.display-parking' | translate"
        [matTooltipShowDelay]="1000">
        {{ 'report.name.parking' | translate }}
      </mat-checkbox>

      <mat-checkbox
        formControlName="isShowTrips"
        [matTooltip]="'message.tooltip.display-trips' | translate"
        [matTooltipShowDelay]="1000">
        {{ 'report.name.trips' | translate }}
      </mat-checkbox>

      <mat-checkbox
        formControlName="isShowPoints"
        [matTooltip]="'message.tooltip.display-points' | translate"
        [matTooltipShowDelay]="1000">
        {{ 'report.name.points' | translate }}
      </mat-checkbox>

      <h5>Отображение видео</h5>

      <button mat-button class="app-button stroked" (click)="onOpenVideoSettings()">
        <mat-icon [ngClass]="{selected: isShowVideo | async}" matIconPrefix>filter_alt</mat-icon>
        <span>{{ 'video.video' | translate }}</span>
      </button>

      <button mat-button class="app-button primary"
              *ngIf="(size$ | async).more('m')"> {{ 'action.close' | translate }}
      </button>
    </div>
  </ng-template>
</form>
