import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {Observable, Subscription} from 'rxjs';
import {map, take} from "rxjs/operators";
import {GlobalStyleClass} from "../../../../../../../common-module/src/lib/app-enums/global-style-class.enum";
import {LocalStorageKey} from '../../../../../../../common-module/src/lib/app-enums/local-storage-key';
import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {Track} from "../../../../../../../common-module/src/lib/modelinterfaces/track.model";
import {ToolbarService} from "../../../../shared/services/toolbar.service";
import {TrackLayerService, TrackOptions} from '../../../../shared/services/track-layer.service';
import {FilterVideoDialogComponent} from "../../video/filter-video-dialog/filter-video-dialog.component";
import {TrackSensorOptionsDialogOpener} from "./track-sensor-options-dialog/track-sensor-options-dialog.opener";
import {UnitDataService} from "../../../../shared/services/unit-data.service";
import {SensorRanges} from "./track-sensor-options-dialog/track-sensor-options-dialog.component";
import {TrackService} from "../../../../../../../common-module/src/lib/services/track.service";

@Component({
  selector: 'app-track-options',
  templateUrl: './track-options.component.html',
  styleUrls: ['./track-options.component.scss']
})

export class TrackOptionsComponent implements OnInit, OnDestroy {

  public isShowVideo: Observable<boolean>;
  public loadingVideoPoints$: Observable<boolean>;
  public size$: Observable<ViewSize>;
  public track$: Observable<Track>;
  public trackOptionsForm: UntypedFormGroup;

  private subscription: Subscription;

  constructor(private dialog: MatDialog,
              private toolbarService: ToolbarService,
              private trackService: TrackService,
              private trackLayerService: TrackLayerService,
              private trackSensorOptionsDialogOpener: TrackSensorOptionsDialogOpener,
              private viewBreakpointService: ViewBreakpointService,
              private unitDataService: UnitDataService,
  ) {

  }

  ngOnInit(): void {
    this.track$ = this.trackLayerService.track$;
    this.size$ = this.viewBreakpointService.size$;
    this.loadingVideoPoints$ = this.trackLayerService.loadingVideoPoints$;
    this.isShowVideo = this.toolbarService.trackVideoPointsFilterParams$.pipe(map(params => !!params));
    const trackOptions = this.getTrackOptions();
    this.trackLayerService.changeTrackOptions(trackOptions);
    this.buildForm(trackOptions);
    this.subscription = this.trackOptionsForm.valueChanges.subscribe(() => {
      this.setTrackOptions({
        isStartFinish: true,
        isShowParkings: this.formValue.isShowParkings,
        isShowTrips: this.formValue.isShowTrips,
        isShowPoints: this.formValue.isShowPoints,
        maxSpeed: this.formValue.maxSpeed !== 0 ? this.formValue.maxSpeed : null,
        minDurationOverSpeed: this.formValue.minDurationOverSpeed ? this.formValue.minDurationOverSpeed : 0,
        sensorRanges: []
      });
    });
  }

  private buildForm(trackOptions: TrackOptions): void {
    this.trackOptionsForm = new UntypedFormGroup({
      isShowParkings: new UntypedFormControl(trackOptions.isShowParkings),
      isShowTrips: new UntypedFormControl(trackOptions.isShowTrips),
      isShowPoints: new UntypedFormControl(trackOptions.isShowPoints),
      maxSpeed: new UntypedFormControl(trackOptions.maxSpeed),
      minDurationOverSpeed: new UntypedFormControl(trackOptions.minDurationOverSpeed)
    });
  }

  get formValue(): any {
    return this.trackOptionsForm.value;
  }

  private getTrackOptions(): TrackOptions {
    let trackOptions = JSON.parse(window.localStorage.getItem(LocalStorageKey.TRACK_OPTIONS));
    if (!trackOptions) {
      trackOptions = {
        isStartFinish: true,
        isShowParkings: true,
        isShowTrips: true,
        isShowPoints: false,
        maxSpeed: 0,
        minDurationOverSpeed: 0
      };
    }
    return trackOptions;
  }

  private setTrackOptions(trackOptions: TrackOptions) {
    window.localStorage.setItem(LocalStorageKey.TRACK_OPTIONS, JSON.stringify(trackOptions));
    this.trackLayerService.changeTrackOptions(trackOptions);
    this.trackLayerService.changeCurrentTrack(this.trackLayerService.instantTrack());
  }

  public onOpenVideoSettings(): void {
    this.dialog.open(FilterVideoDialogComponent, {
      panelClass: GlobalStyleClass.DIALOG_SIZE_LIMIT,
      autoFocus: false,
      data: {
        isTrackOptions: true
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onOpenSensorDialog() {
    this.unitDataService.unitData$.pipe(take(1)).subscribe(unitData => {
      this.trackSensorOptionsDialogOpener.open({
        data: {
          sensors: unitData.sensorLastValue,
          options: this.getTrackOptions().sensorRanges ?? []
        }
      }).pipe(take(1))
        .subscribe(result => {
          if (!result.isCancel && result.data) {
            this.updateSensorRangeOptions(result.data);
            const track = this.trackLayerService.instantTrack();
            const sensorSet = new Set(result.data.map(d => d.sensor.id));
            const allSensorsPresent = [...sensorSet].every(sensorId =>
              track.storage.sensors.some(sensor => sensor.id === sensorId)
            );
            if (!allSensorsPresent) {
              const bo = this.trackLayerService.instantTrack().buildOptions;
              this.trackService.getByUnitId(bo.unit.id, bo.startTime, bo.finishTime, 'ru', result.data.map(d => d.sensor.id))
                .subscribe(track => this.trackLayerService.changeCurrentTrack(track))
            }
          }
        })
    })
  }

  private updateSensorRangeOptions(ranges: SensorRanges[]) {
    let trackOptions = this.getTrackOptions();
    trackOptions.sensorRanges = ranges;
    this.setTrackOptions(trackOptions);
  }
}
