<app-dialog [title]="'titles.select-sensors-to-generate-chart' | translate">

  <ng-container appDialogStickyHeader>
    <div class="header-container mat-typography">
      <div>
        {{ 'message.info.selected-count' | translate: {count: '(' + selectedSensorIds.length + ')'} }}
      </div>
      <mat-checkbox #allSelected (click)="toggleAllSelection()" labelPosition="before">
        {{ 'action.select-all' | translate }}:
      </mat-checkbox>
    </div>
  </ng-container>

  <ng-container appDialogContent>

    <mat-selection-list class="selection-list mat-typography" #sensorList>
      <div class="options">
        <div *ngFor="let sensor of filteredSensors">
          <mat-list-option
            value="{{ sensor.id }}"
            (click)="handleSelection()"
            [selected]="lastSelectedSensorNames.includes(sensor.name)">
            <p>
              <span>{{ sensor.nameTranslated }}:&nbsp;</span>
              <span class="sensor-text">
            {{ sensor.value | sensorValue: 1 }}&nbsp;{{ sensor.unitOfMeasure.nameTranslated }}
          </span>
            </p>
          </mat-list-option>
        </div>
      </div>
    </mat-selection-list>

  </ng-container>

  <ng-container appDialogActions>

    <app-button-cancel (cancel)="onCancel()">
    </app-button-cancel>

    <app-button-primary (action)="onSubmit()"
                        [disabled]="this.selectedSensorIds.length === 0"
                        [text]="'action.build' | translate">
    </app-button-primary>
  </ng-container>

</app-dialog>

