import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppCommonModule } from '../../../../../../common-module/src/lib/app-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { EditVehicleModule } from '../vehicle/edit-vehicle.module';
import { AddressCardComponent } from './cards/address-card/address-card.component';
import { DeviceStatusCardComponent } from './cards/device-status-card/device-status-card.component';
import { DriverCardComponent } from './cards/driver-card/driver-card.component';
import { ExpireInfoCardComponent } from './cards/expire-info-card/expire-info-card.component';
import { InfoCardComponent } from './cards/info-card/info-card.component';
import { SensorsCardComponent } from './cards/sensors-card/sensors-card.component';
import { SendCommandDialogComponent } from "./cards/unit-info-card/send-command-dialog/send-command-dialog.component";
import { UnitInfoCardComponent } from './cards/unit-info-card/unit-info-card.component';
import { UnitStatusCardComponent } from './cards/unit-status-card/unit-status-card.component';
import { VehicleCardComponent } from './cards/vehicle-card/vehicle-card.component';
import { UnitInfoCarouselComponent } from './unit-info-carousel.component';

@NgModule({
  declarations: [
    UnitInfoCarouselComponent,
    AddressCardComponent,
    DeviceStatusCardComponent,
    DriverCardComponent,
    ExpireInfoCardComponent,
    InfoCardComponent,
    SensorsCardComponent,
    UnitInfoCardComponent,
    UnitStatusCardComponent,
    VehicleCardComponent,
    SendCommandDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    EditVehicleModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    UnitInfoCarouselComponent
  ]
})

export class UnitInfoCarouselModule {
}
