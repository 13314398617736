import {ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {merge, Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {
  AppVersionComponent
} from '../../../../../common-module/src/lib/app-components/app-version/app-version.component';
import {GlobalStyleClass} from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {Tab} from '../../../../../common-module/src/lib/app-enums/tab';
import {AuthUserService} from '../../../../../common-module/src/lib/app-services/auth-user.service';
import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {Customization} from '../../../../../common-module/src/lib/modelinterfaces/customization.model';
import {NoticeUtil} from '../../../../../common-module/src/lib/utils/notice-util';
import {environment} from '../../../environments/environment';
import {AppCustomizationService} from '../../../../../common-module/src/lib/app-services/app-customization.service';
import {NotificationManagerService} from '../../shared/services/notification-manager.service';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';
import {InformingDialogComponent} from './informing-dialog/informing-dialog.component';
import {PublicLinksDialogComponent} from './public-links-dialog/public-links-dialog.component';
import {
  SettingsDialogComponent
} from '../../../../../common-module/src/lib/app-dialog/settings-dialog/settings-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent implements OnInit {
  @Input() templateMiddle: TemplateRef<any>;

  @Input() templateRight: TemplateRef<any>;

  public customization$: Observable<Customization>;
  public header: string;
  public headerButtons: { value: Tab; icon: string; text: string }[] = [
    {value: Tab.MAP, icon: 'map', text: 'term.map'},
    {value: Tab.ECO_DRIVING, icon: 'eco', text: 'term.eco-driving'},
    {value: Tab.SENSORS, icon: 'settings_remote', text: 'term.sensors'},
    {value: Tab.REPORT, icon: 'library_books', text: 'term.report'},
    {value: Tab.CHART, icon: 'insert_chart', text: 'term.chart'},
    {value: Tab.DRIVERS, icon: 'sports_motorsports', text: 'term.drivers'},
    {value: Tab.VEHICLE, icon: 'drive_eta', text: 'term.vehicle-park'},
    {value: Tab.NOTIFICATION, icon: 'notifications_active', text: 'term.notifications'},
    {value: Tab.VIDEO, icon: 'videocam', text: 'video.video'}
  ];
  public size$: Observable<ViewSize>;
  public tab$: Observable<Tab>;
  public tabName = Tab;
  public userName$: Observable<string>;
  public noticeSum$: Observable<string>;

  public selectTab =  {value: Tab.MAP, icon: 'map', text: 'term.map'};

  constructor(private appCustomizationService: AppCustomizationService,
              private authUserService: AuthUserService,
              private dialog: MatDialog,
              private notificationManagerService: NotificationManagerService,
              private router: Router,
              private viewBreakpointService: ViewBreakpointService) {
    this.tab$ = merge(
      of(this.router.parseUrl(this.router.url).root.children.primary?.segments[0]?.toString() as Tab),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.split('/')[1] as Tab)));
  }

  ngOnInit(): void {
    this.header = environment.HEADER;
    this.customization$ = this.appCustomizationService.customization$;
    this.size$ = this.viewBreakpointService.size$;
    this.userName$ = this.authUserService.currentUser$.pipe(map(user => user?.name || ''));
    this.noticeSum$ = this.notificationManagerService.noticeCount$.pipe(map(counts => NoticeUtil.transformCount(counts?.sum)));
  }

  public onOpenSettingDialog(): void {
    this.dialog.open(SettingsDialogComponent, {
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT],
      width: '320px',
      data: {
        user: this.authUserService.getInstant(),
      }
    });
  }

  public onOpenPublicLinksDialog(): void {
    this.dialog.open(PublicLinksDialogComponent, {
      minHeight: '300px',
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT],
      data: {
        user: this.authUserService.getInstant()
      }
    });
  }

  public onOpenInformingDialog(): void {
    this.dialog.open(InformingDialogComponent, {
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT],
      autoFocus: false,
      data: {
        user: this.authUserService.getInstant()
      }
    });
  }

  public onOpenCommandTemplates(): void {
    this.router.navigate([Tab.COMMAND_TEMPLATES]).then();
  }

  public onOpenChangePasswordDialog(): void {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '350px',
      data: {
        user: this.authUserService.getInstant()
      }
    });
  }

  public onSelectorChange(tab: { value: Tab; icon: string; text: string }): void {
    if (!this.router.url.includes(tab.value)) {
      this.router.navigate([tab.value]).then();
    }
    this.selectTab = tab;
  }

  public onOpenAboutProgram(): void {
    this.dialog.open(AppVersionComponent, {
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT]
    });
  }

  public onExit(): void {
    window.localStorage.removeItem(LocalStorageKey.BEARER_TOKEN);
    this.authUserService.change(null);
    this.router.navigate([Tab.LOGIN]).then();
  }

  onLogistic() {
    this.router.navigate([Tab.LOGISTIC, 'about']).then();
  }

  onOpenWikiUrl() {
    window.open('https://www.wiki.aurora-soft.by/', '_blank');
  }
}
