export class ZIndexLayer {
  public static readonly ZERO = 0;
  public static readonly WEATHER = 2;
  public static readonly TRACK = 100;
  public static readonly TRACK_MAX_SPEED = 110;
  public static readonly TRACK_SELECTED_TRIP = 115;
  public static readonly TRACK_CHECK_POINTS = 120;
  public static readonly PARKING_ICONS = 600;
  public static readonly CLUSTER = 600;
  public static readonly TRACK_MARKER = 610;
  public static readonly ANIMATION_MARKER = 1000;
  public static readonly GEOFENCE = 2000;
  public static readonly GEOFENCE_DRAW = 2100;
  public static readonly MEASURE = 600;
  public static readonly MAP_CENTER = 2100;
}
