import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { LocalStorageKey } from '../../../../../../../common-module/src/lib/app-enums/local-storage-key';
import { SensorLastValue } from '../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';

@Component({
  selector: 'app-chart-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChartDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('sensorList', {static: false}) sensorList: MatSelectionList;
  @ViewChild('allSelected', {static: false}) allSelected: MatListOption;

  public lastSelectedSensorNames: string[] = [];
  public selectedSensorIds: number[] = [];
  public selectAllCheckbox = false;
  public filteredSensors: SensorLastValue[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { sensors: SensorLastValue[] },
              private dialogRef: MatDialogRef<ChartDialogComponent>) {
  }

  ngOnInit(): void {
    this.lastSelectedSensorNames = JSON.parse(localStorage.getItem(LocalStorageKey.SENSORS_LAST_SELECTED));
    if (!this.lastSelectedSensorNames) {
      this.lastSelectedSensorNames = [];
    }
    this.filteredSensors = this.data.sensors.filter(el => el.visible);
    this.selectedSensorIds = this.filteredSensors.filter(s => this.lastSelectedSensorNames.includes(s.name)).map(s => s.id);
  }

  ngAfterViewInit(): void {
    this.selectAllCheckbox = this.filteredSensors.length === this.selectedSensorIds.length;
    setTimeout(() => { // use setTimeout for avoid error: Expression has changed after it was checked
      if (this.selectAllCheckbox) {
        this.sensorList.selectAll();
      }
    });
  }

  public toggleAllSelection(): void {
    this.selectAllCheckbox = !this.selectAllCheckbox;
    this.handlerAllSelection();
  }

  private handlerAllSelection(): void {
    if (this.selectAllCheckbox) {
      this.sensorList.selectAll();
      this.selectedSensorIds = this.sensorList.selectedOptions.selected.map(v => Number(v.value)).filter(el => !!el);
    }
    if (!this.selectAllCheckbox) {
      this.sensorList.deselectAll();
      this.selectedSensorIds = [];
      this.updateLastSelectedSensorName();
    }
  }

  private updateLastSelectedSensorName(): void {
    localStorage.setItem(
      LocalStorageKey.SENSORS_LAST_SELECTED,
      JSON.stringify(this.filteredSensors.filter(s => this.selectedSensorIds.includes(s.id)).map(s => s.name))
    );
  }

  public handleSelection(): void {
    this.selectedSensorIds = this.sensorList.selectedOptions.selected.map(v => Number(v.value)).filter(el => !!el);
    this.updateLastSelectedSensorName();
    this.selectAllCheckbox = this.filteredSensors.length === this.selectedSensorIds.length;
    if (this.selectAllCheckbox) {
      this.allSelected.toggle();
    }
    if (this.allSelected.selected && !this.selectAllCheckbox) {
      this.allSelected.toggle();
    }
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.dialogRef.close({sensorIds: this.selectedSensorIds});
  }
}
