import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';

import { AppCommonModule } from '../../../../../../common-module/src/lib/app-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { EngineHoursFormComponent } from './engine-hours-tab/engine-hours-form.component';
import { EditUnitViewDialogComponent } from './unit-view-tab/edit-unit-view-dialog/edit-unit-view-dialog.component';
import { NormConsumptionFormsComponent } from "./norm-consumption-tab/norm-consumption-forms.component";
import { OdometerFormComponent } from "./odometer-tab/odometer-form.component";
import { EditVehicleDialogComponent } from './vehicle-info-tab/edit-vehicle-dialog/edit-vehicle-dialog.component';
import { VehicleFormComponent } from "./vehicle-info-tab/vehicle-form.component";
import { UnitViewFormComponent } from './unit-view-tab/unit-view-form.component';
import { TripFormComponent } from './trip-tab/trip-form.component';
import { EcoDrivingTabComponent } from './eco-driving-tab/eco-driving-tab.component';
import { DeleteCriterionDialogComponent } from './eco-driving-tab/delete-criterion-dialog/delete-criterion-dialog.component';
import {
  MileageClassifierScannedDialogComponent
} from "./norm-consumption-tab/mileage-classifier-scanned-dialog/mileage-classifier-scanned-dialog.component";
import {
  MileageClassifierScannedMap
} from "./norm-consumption-tab/mileage-classifier-scanned-dialog/mileage-classifier-scanned-map/mileage-classifier-scanned.map";
import {
  MileageClassifierScannedMapComponent
} from "./norm-consumption-tab/mileage-classifier-scanned-dialog/mileage-classifier-scanned-map/mileage-classifier-scanned-map.component";

@NgModule({
  declarations: [
    EditVehicleDialogComponent,
    EditUnitViewDialogComponent,
    VehicleFormComponent,
    NormConsumptionFormsComponent,
    OdometerFormComponent,
    UnitViewFormComponent,
    TripFormComponent,
    EcoDrivingTabComponent,
    EngineHoursFormComponent,
    DeleteCriterionDialogComponent,
    MileageClassifierScannedDialogComponent,
    MileageClassifierScannedMapComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedModule
  ],
  providers: [{provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}],
  bootstrap: [],
  exports: [
    EditVehicleDialogComponent,
    EditUnitViewDialogComponent,
    VehicleFormComponent,
    NormConsumptionFormsComponent,
    OdometerFormComponent,
    UnitViewFormComponent,
    TripFormComponent,
    EcoDrivingTabComponent,
    EngineHoursFormComponent
  ]
})

export class EditVehicleModule {
}
