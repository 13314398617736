<div [formGroup]="form">

  <mat-form-field class="input-color flex1 bound-margin-left" [floatLabel]="'always'">
    <mat-label>{{ label ? label : ('term.color')|translate }}</mat-label>
    <input matInput
           readonly
           [matMenuTriggerFor]="menu"
           [formControlName]="controls.COLOR"/>
    @if (form.value[controls.COLOR]) {
      <mat-icon [matMenuTriggerFor]="menu" matIconPrefix [style.color]="form.value[controls.COLOR]">{{ previewIcon }}
      </mat-icon>
    } @else {
      <mat-icon [matMenuTriggerFor]="menu" matIconPrefix class="crossed-out text-divider-color">{{ previewIcon }}
      </mat-icon>
    }
  </mat-form-field>
  <mat-menu #menu="matMenu">
    @if (nullColor) {
      <button mat-button (click)="clear()">
        <mat-icon class="crossed-out text-divider-color">{{ previewIcon }}</mat-icon>
        Отключить
      </button>
    }
    <app-color-palette [collectionColors]="collectionColors"
                       (changed)="onChangeColor($event)">
    </app-color-palette>
  </mat-menu>
</div>
