import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, takeWhile} from 'rxjs/operators';

import {GlobalStyleClass} from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {Tab} from '../../../../../common-module/src/lib/app-enums/tab';
import {DateRangeI} from '../../../../../common-module/src/lib/app-interfaces/date-range-i';
import {DvrFilters} from "../../../../../common-module/src/lib/app-models/dvr-filters.models";
import {AppMessageService} from '../../../../../common-module/src/lib/app-services/app-message.service';
import {ReportType} from '../../../../../common-module/src/lib/modelinterfaces/enums/report-type';
import {ReportOptions} from '../../../../../common-module/src/lib/modelinterfaces/report-options.model';
import {SensorLastValue} from '../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';
import {UnitShort} from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {ReportService} from '../../../../../common-module/src/lib/services/report.service';
import {SensorChartExtService} from '../../../../../common-module/src/lib/services/sensor-chart-ext.service';
import {TrackService} from '../../../../../common-module/src/lib/services/track.service';
import {ChartDialogComponent} from '../../system/main/toolbar/chart-dialog/chart-dialog.component';
import {ReportBuildOptions} from '../../system/main/toolbar/report-options/report-options.component';
import {ToolbarState} from '../constants/enums/toolbar-state';
import {AuthUserService} from '../../../../../common-module/src/lib/app-services/auth-user.service';
import {ChartDataService} from './chart-data.service';
import {GeofenceListService} from './geofence-list.service';
import {ReportDownloadService} from './report-download.service';
import {SelectedService} from './selected.service';
import {TrackLayerService} from './track-layer.service';
import {UnitDataService} from './unit-data.service';
import {EnvironmentHelper} from "../../../../../common-module/src/lib/services/environment-helper";

export interface ToolbarLoadingState {
  state: ToolbarState;
  closeDialog: boolean;
}

@Injectable({providedIn: 'root'})

export class ToolbarService {

  private dateTimeRangeSource = new BehaviorSubject<DateRangeI>(null);
  private lastSelectedUnit: UnitShort;
  public reportOptionsSource = new BehaviorSubject<ReportOptions[]>([]);
  private selectedUnitIdsSet: Set<number>;
  private stateSource = new BehaviorSubject<ToolbarLoadingState>({state: ToolbarState.REPORT, closeDialog: false});
  private trackVideoPointsFilterParamsSource = new BehaviorSubject<DvrFilters>(null);
  private toolbarOptions = new BehaviorSubject<ToolbarState[]>([ToolbarState.REPORT, ToolbarState.TRACK, ToolbarState.CHART])
  private userLanguage: string;

  constructor(private chartDataService: ChartDataService,
              private appUserMessageService: AppMessageService,
              private authUserService: AuthUserService,
              private dialog: MatDialog,
              private geofenceListService: GeofenceListService,
              private reportDownloadService: ReportDownloadService,
              private reportService: ReportService,
              private selectedService: SelectedService,
              private sensorChartExtService: SensorChartExtService,
              private router: Router,
              private trackService: TrackService,
              private trackLayerService: TrackLayerService,
              private translateService: TranslateService,
              private unitDataService: UnitDataService,
              private envHelper: EnvironmentHelper) {

    this.userLanguage = this.translateService.currentLang;
    this.selectedService.lastSelectedUnit$.subscribe(u => this.lastSelectedUnit = u);
    this.selectedService.selectedUnitIdsSet$.subscribe(list => this.selectedUnitIdsSet = list);
  }

  get dateTimeRange$(): Observable<DateRangeI> {
    return this.dateTimeRangeSource.asObservable();
  }

  get state$(): Observable<ToolbarLoadingState> {
    return this.stateSource.asObservable();
  }

  get toolbarOptions$(): Observable<ToolbarState[]> {
    return this.toolbarOptions.asObservable();
  }

  get trackVideoPointsFilterParams$(): Observable<DvrFilters> {
    return this.trackVideoPointsFilterParamsSource.asObservable();
  }

  public init(): void {
    this.authUserService.currentUser$
      .pipe(filter(authUser => authUser && authUser.id != null))
      .subscribe(authUser => this.nextToolbarOptions(authUser.id))

    if (!this.dateTimeRangeSource.getValue()) {
      this.authUserService.getTodayRange$().subscribe(today => this.changeDateTimeRange(today))
    }
    if (window.localStorage.getItem(LocalStorageKey.TOOLBAR_STATE)) {
      this.changeToolbarState(<ToolbarState>window.localStorage.getItem(LocalStorageKey.TOOLBAR_STATE));
    }
    this.reportService.getListOptions(this.translateService.currentLang)
      .subscribe(list => this.changeReportOptions(list))
  }

  private nextToolbarOptions(authUserId: number): void {
    const restrictions = this.envHelper.toolbarRestrictions;
    const result: ToolbarState[] = [];
    const stateRestrictions = new Map([
      [ToolbarState.REPORT, new Set(restrictions.DISABLE_REPORT)],
      [ToolbarState.TRACK, new Set(restrictions.DISABLE_TRACK)],
      [ToolbarState.CHART, new Set(restrictions.DISABLE_CHART)],
    ]);
    stateRestrictions.forEach((restrictedUsers, state) => {
      if (!restrictedUsers.has(authUserId)) {
        result.push(state);
      }
    });
    this.toolbarOptions.next(result);
  }

  public changeDateTimeRange(range: DateRangeI): DateRangeI {
    this.dateTimeRangeSource.next(range);
    return range;
  }

  public changeToolbarState(state: ToolbarState, closeDialog: boolean = false): void {
    this.stateSource.next({state: state, closeDialog: closeDialog});
    if (state !== ToolbarState.LOADING) {
      window.localStorage.setItem(LocalStorageKey.TOOLBAR_STATE, state);
    }
  }

  private changeReportOptions(options: ReportOptions[]): void {
    this.reportOptionsSource.next(options);
  }

  /**
   * type: 'track' | 'report' | 'chart'
   * options: addition options. Ex for report it is a selected reportName;
   */
  public startBuild(type: ToolbarState, options: ReportBuildOptions, $event: MouseEvent): void {
    if ($event && $event.ctrlKey && $event.shiftKey) {
      this.buildReport({reportName: 'MESSAGE', type: ReportType.NONE, uniteTables: false}, $event);
      return;
    }
    if (type === ToolbarState.REPORT) {
      this.buildReport(options, $event);
      return;
    }
    if (type === ToolbarState.TRACK) {
      this.buildTrack();
      return;
    }
    if (type === ToolbarState.CHART) {
      this.buildChart();
    }
  }

  private openSnackBar(message: string, isError: boolean = false): void {
    this.appUserMessageService.openSnackBar(message, isError);
  }

  private buildReport(options: ReportBuildOptions, event?: MouseEvent): void {
    if (this.selectedUnitIdsSet.size === 0) {
      this.openSnackBar('action.select-unit');
      return;
    }
    const useCache = event ? !event.ctrlKey : true;
    this.changeToolbarState(ToolbarState.LOADING);
    const dateTimeRange = this.getDateTimeRange();
    const geofenceIds = this.geofenceListService.getSelectedList(Tab.REPORT).map(g => g.id);
    this.reportService.getList(options.reportName, options.type, dateTimeRange.startDate.toISOString(), dateTimeRange.endDate.toISOString(),
      this.selectedUnitIdsSet, this.userLanguage, options.uniteTables, useCache, geofenceIds)
      .subscribe(
        reports => {
          if (reports.length === 0) {
            this.openSnackBar('message.error.report-not-available-for-selected-unit-or-units', true);
            this.changeToolbarState(ToolbarState.REPORT);
            return;
          }
          this.reportDownloadService.changeReport(reports);
          this.changeToolbarState(ToolbarState.REPORT, true);
        },
        error => this.handlingErrorLoadData(error, ToolbarState.REPORT)
      );
  }

  public getDateTimeRange(): DateRangeI {
    return this.dateTimeRangeSource.getValue();
  }

  private buildTrack(): void {
    if (!this.lastSelectedUnit) {
      this.openSnackBar('action.select-unit');
      return;
    }
    this.changeToolbarState(ToolbarState.LOADING);
    const dateTimeRange = this.getDateTimeRange();
    this.trackService.getByUnitId(this.lastSelectedUnit.id, dateTimeRange.startDate.toISOString(), dateTimeRange.endDate.toISOString(),
      this.userLanguage)
      .subscribe(
        value => {
          const track = value;
          if (track.storage.sensorDataList.length === 0 && track.parkings.length === 0) {
            this.handlingErrorNotEnoughData(ToolbarState.TRACK);
            return;
          }
          this.trackVideoPointsFilterParamsSource.next(null);
          this.router.navigate([Tab.MAP]).then();
          this.trackLayerService.changeCurrentTrack(track);
          this.changeToolbarState(ToolbarState.TRACK, true);
        },
        error => this.handlingErrorLoadData(error, ToolbarState.TRACK)
      );
  }

  public buildChart(): void {
    if (!this.lastSelectedUnit) {
      this.openSnackBar('action.select-unit');
      this.router.navigate([Tab.MAP]).then();
      return;
    }
    this.changeToolbarState(ToolbarState.LOADING);
    this.unitDataService.unitData$
      .pipe(
        takeWhile((val, index) => !val && index < 1, true)
      )
      .subscribe(
        data => {
          if (data) {
            this.changeToolbarState(ToolbarState.CHART, true);
            this.openChartDialog(this.getDateTimeRange(), data.sensorLastValue);
          }
        },
        error => this.handlingErrorLoadData(error, ToolbarState.CHART)
      );
  }

  private openChartDialog(dateTimeRange: DateRangeI, sensors: SensorLastValue[]): void {
    const dialogRef = this.dialog.open(ChartDialogComponent, {
      height: '600px',
      data: {
        sensors: sensors
      }
    });
    dialogRef.afterClosed().subscribe(r => {
      if (!r?.sensorIds) {
        this.changeToolbarState(ToolbarState.CHART);
        return;
      }
      if (r?.sensorIds?.length > 0) {
        this.changeToolbarState(ToolbarState.LOADING);
        this.sensorChartExtService.getByUnitId(this.lastSelectedUnit.id, dateTimeRange.startDate.toISOString(),
          dateTimeRange.endDate.toISOString(), r.sensorIds, null, null, this.userLanguage)
          .subscribe(
            data => {
              const sensorChart = data;
              if (sensorChart?.segments.length > 0) {
                this.chartDataService.change(sensorChart);
                this.router.navigate([Tab.CHART]).then();
                this.changeToolbarState(ToolbarState.CHART, true);
              } else {
                this.handlingErrorNotEnoughData(ToolbarState.CHART);
              }
            },
            error => this.handlingErrorLoadData(error, ToolbarState.CHART));
      } else {
        this.handlingErrorNotEnoughData(ToolbarState.CHART);
      }
    });
  }

  private handlingErrorNotEnoughData(state: ToolbarState): void {
    this.openSnackBar('message.error.not-enough-data', true);
    this.changeToolbarState(state);
  }

  private handlingErrorLoadData(error: Error, state: ToolbarState): void {
    this.openSnackBar('message.error.load-data', true);
    this.changeToolbarState(state);
  }

  public changeTrackVideoPointsFilterParams(filters: DvrFilters): void {
    this.trackVideoPointsFilterParamsSource.next(filters);
  }

  public instantTrackVideoPointsFilterParams(): DvrFilters {
    return this.trackVideoPointsFilterParamsSource.getValue();
  }
}
