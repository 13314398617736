import {Component, Input, OnInit} from '@angular/core';
import {Logistic} from "../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {IconCtx} from "../../../system/logistic/common/utils/icon-ctx";
import {AppColor} from "../../../../../../common-module/src/lib/app-enums/app-color";

@Component({
  selector: 'app-route-status-icon',
  templateUrl: './route-status-icon.component.html',
  styleUrls: ['./route-status-icon.component.scss']
})
export class RouteStatusIconComponent implements OnInit {

  @Input() status: Logistic.Status;

  iconCtx: IconCtx;

  ngOnInit(): void {
    this.iconCtx = this.buildIcon();
  }

  private buildIcon() {
    switch (this.status) {
      case Logistic.Status.ASSIGNED:
        return {name: 'pending', color: AppColor.PRIMARY_LIGHT, description: 'Ожидает выполнения'}
      case Logistic.Status.IN_PROGRESS:
        return {name: 'sync', color: AppColor.PRIMARY, description: 'Выполняется'}
      case Logistic.Status.COMPLETED:
        return {name: 'check_circle', color: AppColor.GREEN, description: 'Выполнено'}
      case Logistic.Status.COMPLETED_LATE:
        return {name: 'schedule', color: AppColor.ORANGE, description: 'Выполнено с опозданием'}
      case Logistic.Status.CANCELLED:
        return {name: 'not_interested', color: AppColor.RED, description: 'Отклонено'}
      default:
        return {name: 'fiber_manual_record', color: AppColor.GRAY, description: 'Неизвестное состояние'}
    }
  }


}
