import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Track } from '../modelinterfaces/track.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class TrackService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getByUnitId(unitId: number, startTime: string, finishTime: string, lang: string, sensorIds: number[] = []): Observable<Track> {
    const url = `${this.environmentHelper.urlV2}/track/unit/${unitId}`;
    let params = new HttpParams();
    params = params.set('startTime', startTime);
    params = params.set('finishTime', finishTime);
    params = params.set('lang', lang);
    if (sensorIds.length > 0) {
      params = params.set('sensorIds', sensorIds.join(','));
    }
    return this.http.get<Track>(url, {params: params}).pipe(
      map(obj => Track.valueOf(obj))
    );
  }
}
