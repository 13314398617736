import { NotificationState } from './enums/notification-state';
import { Geofence } from './geofence.model';
import { Interaction } from './interaction.model';
import { ScheduleRange } from './schedule-range.model';
import { UnitShort } from './unit-short.model';
import { UserShort } from './user-short.model';

export class NotificationSource {

  constructor(public id: number,
              public name: string,
              public description: string,
              public state: NotificationState,
              public type: string,
              public typeTranslated: string,
              public message: string,
              public params: object,
              public timeFilter: ScheduleRange,
              public pendingSeconds: number,
              public geofences: Geofence[],
              public units: UnitShort[],
              public user: UserShort,
              public color: string,
              public interaction: Interaction) {
  }

  public static valueOf(o: NotificationSource): NotificationSource {
    return o ? new NotificationSource(o.id, o.name, o.description, o.state, o.type, o.typeTranslated, o.message, o.params, o.timeFilter,
        o.pendingSeconds, Geofence.valuesOf(o.geofences), UnitShort.valuesOf(o.units), UserShort.valueOf(o.user), o.color,
        Interaction.valueOf(o.interaction)) : null;
  }

  public static valuesOf(list: NotificationSource[]): NotificationSource[] {
    return list ? list.map(o => NotificationSource.valueOf(o)) : [];
  }
}
