<div class="menu-options">
  <mat-selection-list #list (selectionChange)="onChangeType(list.selectedOptions.selected)">
    <mat-list-option
      *ngFor="let type of [reportType.DAYS, reportType.STATISTIC]"
      [checkboxPosition]="'before'"
      [value]="type"
      [selected]="isSelected(type)"
      [disabled]="!isAvailable(type)"
      (click)="$event.stopPropagation()"
      class="list-option"
    >
      <span *ngIf="type === reportType.DAYS">{{ 'action.group-by-days' | translate }}</span>
      <span *ngIf="type === reportType.STATISTIC">{{ 'term.statistic' | translate }}</span>
    </mat-list-option>
  </mat-selection-list>

  <mat-slide-toggle
    class="slide-toggle"
    (click)="$event.stopPropagation()"
    (change)="onToggleUniteTables($event)"
    [checked]="lastSelectedOptions.uniteTables"
  >
    {{ 'action.unite-tables' | translate }}
  </mat-slide-toggle>

  <mat-slide-toggle
    *ngIf="hasGeofences"
    class="slide-toggle"
    (click)="$event.stopPropagation()"
    (change)="onToggleSelectGeofences($event)"
    [checked]="isSelectedGeofences">
    {{ 'action.select-geofences' | translate }}
  </mat-slide-toggle>

  <app-select-geofences *ngIf="hasGeofences && isSelectedGeofences" (click)="$event.stopPropagation()"></app-select-geofences>
</div>
