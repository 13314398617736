import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppMessageService } from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { ViewBreakpointService, ViewSize } from '../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { NotificationState } from '../../../../../../../common-module/src/lib/modelinterfaces/enums/notification-state';
import { Interaction } from '../../../../../../../common-module/src/lib/modelinterfaces/interaction.model';
import { NotificationActionCommand } from '../../../../../../../common-module/src/lib/modelinterfaces/notification-action-command.model';
import { NotificationActionText } from '../../../../../../../common-module/src/lib/modelinterfaces/notification-action-text.model';
import { NotificationFormConfig } from '../../../../../../../common-module/src/lib/modelinterfaces/notification-form-config.model';
import { NotificationSource } from '../../../../../../../common-module/src/lib/modelinterfaces/notification-source.model';
import { NotificationType } from '../../../../../../../common-module/src/lib/modelinterfaces/notification-type.model';
import { Notification } from '../../../../../../../common-module/src/lib/modelinterfaces/notification.model';
import { UnitShort } from '../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import { NotificationSourceService } from '../../../../../../../common-module/src/lib/services/notification-source.service';
import { NotificationTypeService } from '../../../../../../../common-module/src/lib/services/notification-type.service';
import { Action } from '../../../../shared/constants/enums/action';
import { AuthUserService } from '../../../../../../../common-module/src/lib/app-services/auth-user.service';
import { SelectedService } from '../../../../shared/services/selected.service';
import { UnitGroupsService } from '../../../../shared/services/unit-groups.service';
import { RuleFormControl } from './rule-settings/rule-settings.component';

@Component({
  selector: 'app-rule-dialog',
  templateUrl: './rule-dialog.component.html',
  styleUrls: ['./rule-dialog.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class RuleDialogComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  public action = Action;
  public currentItem: NotificationType | NotificationSource;
  public currentRule: NotificationSource;
  public filteredUnitsFromSideBar: UnitShort[] = [];
  public unusableUnits: UnitShort[] = [];
  public formConfig: NotificationFormConfig;
  public notificationState = NotificationState;
  public ruleForm: UntypedFormGroup;

  public sourceListOthers: NotificationSource[] = [];
  public sourceListByLastSelectedUnit: NotificationSource[] = [];
  public searchIn: string;
  public selectAll = false;
  public selectedUnitList: UnitShort[] = [];
  public lastSelectedUnit: UnitShort;
  public size$: Observable<ViewSize>;
  public showAccordion = false;
  public selectedRuleIdToDelete: number;
  public typeList: NotificationType[] = [];
  public unitList: UnitShort[] = [];

  private selectedUnitListFromSideBar: UnitShort[] = [];
  private sourceList: NotificationSource[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { action: Action; notification?: Notification },
    private dialogRef: MatDialogRef<RuleDialogComponent>,
    private authUserService: AuthUserService,
    private appMessageService: AppMessageService,
    private notificationSourceService: NotificationSourceService,
    private notificationTypeService: NotificationTypeService,
    private uiSpinnerService: UiSpinnerService,
    private unitGroupsService: UnitGroupsService,
    private selectedService: SelectedService,
    private translateService: TranslateService,
    private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnInit(): void {
    this.size$ = this.viewBreakpointService.size$;
    if (this.data.notification?.source) { // skip the step "Select rule"
      this.currentItem = this.data.notification?.source;
      this.getUnitList(this.data.notification?.source);
      return;
    }

    this.uiSpinnerService.show();
    if (this.data.action === Action.ADD) {
      this.notificationTypeService.getList(this.translateService.currentLang)
        .subscribe(list => {
            this.typeList = list;
            this.uiSpinnerService.stop();
          }
        );
    } else { // edit rule
      this.notificationSourceService.getList(this.translateService.currentLang).subscribe(list => {
          this.sourceList = list;
          this.separateRuleListByLastSelectedUnit();
          this.uiSpinnerService.stop();
        }
      );
    }
  }

  private separateRuleListByLastSelectedUnit(): void {
    this.lastSelectedUnit = this.selectedService.getInstantUnit();
    if (this.lastSelectedUnit) {
      this.sourceListByLastSelectedUnit =
        this.sourceList.filter(rule => rule.units.some(unit => unit.id === this.lastSelectedUnit.id));
      this.sourceListOthers =
        this.sourceList.filter(rule => !this.sourceListByLastSelectedUnit.some(el => el.id === rule.id));
    } else {
      this.sourceListOthers = this.sourceList;
    }
  }

  public checkIndex($event: StepperSelectionEvent): void {
    if ($event.selectedIndex !== this.stepper.steps.length - 1) {
      return;
    }
    // to process only when the last step is opened
    if (!this.selectedUnitList || this.selectedUnitList.length === 0) {
      this.appMessageService.openSnackBar('action.select-unit', true);
      return;
    }
    this.currentItem?.type ? this.configureForm() : this.appMessageService.openSnackBar('message.error.no-data', true);
  }

  private configureForm(): void {
    this.uiSpinnerService.show();
    this.notificationSourceService
      .setUnitsAndGetFormConfig(this.currentItem.type, this.selectedUnitList.map(el => el.id), this.translateService.currentLang)
      .subscribe(config => {
          this.formConfig = config;
          this.uiSpinnerService.stop();
        }
      );
  }

  public goSelectUnit(item: NotificationType | NotificationSource): void {
    this.stepper.next();
    this.currentItem = item;
    this.getUnitList(item);
  }

  private getUnitList(item: NotificationType | NotificationSource): void {
    let param: string | number = 0;
    if (item instanceof NotificationType) {
      param = item.type;
    }
    if (item instanceof NotificationSource) {
      param = item.id;
    }
    if (item.type) {
      this.uiSpinnerService.show();
      this.notificationSourceService.getUnitsByNotificationType(param)
        .subscribe(unitIdList => {
            this.unitList = [];
            const unusableSelectedUnitIds = new Set();
            const usableSelectedUnitIds = new Set();
            this.selectedService.getInstantUnitIdsSet().forEach(id => {
              if (unitIdList.includes(id)) {
                usableSelectedUnitIds.add(id);
              } else {
                unusableSelectedUnitIds.add(id);
              }
            });

            this.unitGroupsService.getInstantWithoutGroups().forEach(unit => {
              if (unitIdList.includes(unit.id)) {
                this.unitList.push(unit);
              }
              if (usableSelectedUnitIds.has(unit.id)) {
                this.selectedUnitListFromSideBar.push(unit);
              }
              if (unusableSelectedUnitIds.has(unit.id)) {
                this.unusableUnits.push(unit);
              }
            });

            if (item instanceof NotificationSource) {
              this.changeSelectedUnitList(item.units);
              if (this.selectedUnitList.length === this.unitList.length) {
                this.selectAll = true;
              }
              this.currentRule = item;
            } else {
              this.changeSelectedUnitList([]);
            }
            this.uiSpinnerService.stop();
          }
        );
    } else {
      this.appMessageService.openSnackBar('message.error.error', true);
      this.dialogRef.close();
    }
  }

  public getUnitNames(units: UnitShort[]): string {
    return units.map(unit => unit.name).join(', ');
  }

  public onToggleRuleState($event: MatSlideToggleChange, item: NotificationSource): void {
    const currentState = $event.checked ? NotificationState.ENABLE : NotificationState.DISABLE;
    this.uiSpinnerService.show();
    this.notificationSourceService.changeRuleState(item.id, currentState).subscribe((rule) => {
        this.appMessageService.openSnackBar('message.info.changes-saved', false);
        this.sourceList = this.sourceList.map(el => {
          if (el.id === item.id) {
            el = rule;
          }
          return el;
        });
        this.separateRuleListByLastSelectedUnit();
        this.uiSpinnerService.stop();
      }
    );
  }

  public onDeleteRule(rule: NotificationSource): void {
    this.selectedRuleIdToDelete = null;
    this.uiSpinnerService.show();
    this.notificationSourceService.deleteRule(rule.id).subscribe(() => {
        this.appMessageService.openSnackBar('message.info.changes-saved', false);
        this.sourceList = this.sourceList.filter(el => el.id !== rule.id);
        this.separateRuleListByLastSelectedUnit();
        this.uiSpinnerService.stop();
      }
    );
  }

  public applySelectedUnits(): void {
    this.changeSelectedUnitList(this.selectedUnitList.concat(this.filteredUnitsFromSideBar));
    if (this.unusableUnits.length > 0) {
      this.showAccordion = true;
    }
  }

  public comparisonId = (option: any, value: any): boolean => {
    if (!option || !value) {
      return false;
    }
    return option.id === value.id;
  };

  public onToggleAllChange(): void {
    this.selectAll = !this.selectAll;
    this.selectAll ? this.changeSelectedUnitList(this.unitList) : this.changeSelectedUnitList([]);
  }

  public isSomeChecked(): boolean {
    return !this.selectAll && this.selectedUnitList.length > 0;
  }

  public changeSelectedUnitList(unitList: UnitShort[]): void {
    this.selectedUnitList = unitList;
    this.filteredUnitsFromSideBar = this.countSelectedUnitsFromSideBar();
    this.isSelectAll();
  }

  private countSelectedUnitsFromSideBar(): UnitShort[] {
    const filteredSelectedUnit = [];
    this.selectedUnitListFromSideBar.forEach(unit => {
      if (unit && !this.selectedUnitList.some(el => el.id === unit.id)) {
        filteredSelectedUnit.push(unit);
      }
    });
    return filteredSelectedUnit;
  }

  private isSelectAll(): void {
    this.selectAll = this.selectedUnitList.length === this.unitList.length;
  }

  public receiveRuleForm(form: UntypedFormGroup): void {
    this.ruleForm = form;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onBackToFirstStep(): void {
    this.unitList = [];
  }

  public onSubmit(): void {
    const newRule = new NotificationSource(
      this.currentRule ? this.currentRule.id : 0,
      this.ruleForm.get(RuleFormControl.NAME).value,
      this.ruleForm.get(RuleFormControl.DESCRIPTION).value,
      NotificationState.ENABLE,
      this.currentItem.type,
      this.currentRule?.typeTranslated || null,
      this.ruleForm.get(RuleFormControl.MESSAGE).value,
      this.getParamsObject(),
      this.ruleForm.get(RuleFormControl.SCHEDULE).value,
      this.ruleForm.get(RuleFormControl.PENDING_TIME).value,
      this.ruleForm.get(RuleFormControl.GEOFENCES).value,
      this.selectedUnitList,
      this.currentRule ? this.currentRule.user : this.authUserService.getInstant(),
      this.ruleForm.get(RuleFormControl.LABEL).value,
      new Interaction(
        this.ruleForm.get(RuleFormControl.TELEGRAM_CHATS)?.value,
        this.ruleForm.get(RuleFormControl.ACTION_COMMAND)?.value ?
          new NotificationActionCommand(
            new NotificationActionText(
              this.ruleForm.get(RuleFormControl.ACTIVATED_TEXT)?.value,
              this.ruleForm.get(RuleFormControl.ACTIVATED_TIMEOUT)?.value
            ),
            new NotificationActionText(
              this.ruleForm.get(RuleFormControl.DEACTIVATED_TEXT)?.value,
              this.ruleForm.get(RuleFormControl.DEACTIVATED_TIMEOUT)?.value
            )
          ) : null,
        this.ruleForm.get(RuleFormControl.PUSH_ENABLED)?.value
      )
    );

    if (this.currentRule) {
      this.notificationSourceService.updateRule(this.translateService.currentLang, newRule)
        .subscribe(rule => this.resultHandling(rule));
    } else {
      this.notificationSourceService.saveRule(this.translateService.currentLang, newRule)
        .subscribe(rule => this.resultHandling(rule));
    }
  }

  private getParamsObject(): object {
    const paramsObject = {};
    this.formConfig.params.forEach(el => paramsObject[el.name] = this.ruleForm.get(el.name).value);
    return paramsObject;
  }

  private resultHandling(rule: NotificationSource): void {
    if (rule) {
      this.appMessageService.openSnackBar('message.info.changes-saved', false);
      this.dialogRef.close(rule);
    } else {
      this.appMessageService.openSnackBar('message.error.error-while-saving', true);
    }
  }

  public asType(value: NotificationType | NotificationSource): NotificationType {
    return value as NotificationType;
  }
}
