import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SensorCalculatorSource } from '../modelinterfaces/sensor-calculator-source.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class SensorCalculatorService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getByUnitId(unitId: number, lang: string): Observable<SensorCalculatorSource> {
    const url = `${this.environmentHelper.urlV2}/sensorCalculatorFormSource/unit/${unitId}`;
    return this.http.get<SensorCalculatorSource>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => SensorCalculatorSource.valueOf(obj))
    );
  }

  public getByTemplateSetId(templateSetId: number, lang: string): Observable<SensorCalculatorSource> {
    const url = `${this.environmentHelper.urlV2}/sensorCalculatorFormSource/sensorTemplateSet/${templateSetId}`;
    return this.http.get<SensorCalculatorSource>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => SensorCalculatorSource.valueOf(obj))
    );
  }
}
